<template>
  <div class="bg-black h-full">
    <div class="lg:h-56 h-32 bg-cover bg-center bg-no-repeat coverImage px-8 md:px-20">
      <div class="container mx-auto px-6 lg:px-0 flex items-center h-full">
        <h4 class="font-bold text-white text-3xl lg:text-5xl relative z-10">About Us</h4>
      </div>
    </div>
    <div class="container mx-auto lg:py-20 py-10 text-white px-6 lg:px-0">
      <div class="grid grid-cols-1 lg:grid-cols-2 lg:col-gap-32 row-gap-16 px-8 md:px-20">
        <!-- Left col-->
        <div class="order-1 lg:order-0">
          <h4 class="font-bold mb-5">Meet the Founder and CEO, Travis Chappell.</h4>
          <p class="mb-5">
            Travis Chappell is a podcast consultant and professional connector. He is the creator and host of Build Your
            Network, a Top 25 Business podcast that is dedicated to helping entrepreneurs cultivate strategic and
            meaningful relationships.</p>
          <p class="mb-5">
            After years of podcasting and hundreds of interviews with some of the most influential and well respected
            entrepreneurs in the world, he found that he kept on getting the same question over and over. “How were you
            able to book the quality of guests you have on your show?” Instead of just ignoring it, he decided to take
            action and build a solution to the problem. That solution is Guestio.</p>
          <p>
            He’s poured countless hours and hundreds of thousands of dollars into producing the highest quality shows
            with the most value possible, and he’s passionate about helping others build their shows as well..</p>
        </div>
        <!--        Right col-->
        <div class="order-0 lg:order-1">
          <img class="rounded-3xl w-full" src="@/assets/images/travis.jpg" alt="">
          <h4 class="font-bold my-8">Founder and CEO, Travis Chappell</h4>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "About",
}
</script>

